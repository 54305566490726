<template>
    <div>
        Placeholder for Manage
    </div>
</template>

<script>
export default {
  name: 'manage-home'
}
</script>
